import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid, Link, TextField, Typography, Divider } from '@material-ui/core';
import * as Actions from 'app/store/actions';
import { isValidEmail, sanitizeFilename } from 'app/utils/helpers';
// @ts-ignore: missing type declaration file
import { useScreenshot } from 'use-react-screenshot';
import ChipInput from 'material-ui-chip-input';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { getProfile } from 'app/store/reducers';
import { Workflow } from 'app/store/types';
import AlertDialog from 'app/components/AlertDialog';
import StyledDropzone from 'app/components/StyledDropzone';

const EXCLUDE_FILE_TYPES = [
	'application/exe',
	'application/dos-exe',
	'application/msdos-windows',
	'application/vnd.microsoft.portable-executable',
	'application/x-exe',
	'application/x-msdos-program',
	'application/x-msdownload',
	'application/x-ms-dos-executable',
	'application/x-winexe',
	'vms/exe'
];
const MAX_FILE_SIZE = 1048576 * 25; // 25MB

const useStyles = makeStyles(theme => ({
	boldText: {
		fontWeight: 600,
		fontSize: '21px'
	},
	normalText: {
		fontWeight: 400,
		color: '#666666',
		fontSize: '14px'
	},
	text: {
		'& .MuiFormHelperText-root.Mui-error': {
			// fontSize: 8,
			margin: 0
		},
		'& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: '#f44336'
		},
		'& .MuiInputBase-root': {
			paddingTop: '8px',
			height: 'auto'
		}
	},
	checkbox: {
		padding: '0px',
		margin: '9px'
	},
	divider: {
		backgroundColor: '#707070'
	},
	items: {
		'& > .MuiGrid-item': {
			padding: '12px 0px'
		},
		'& > .MuiGrid-item:first-child': {
			padding: '0px 0px 12px'
		},
		'& > .MuiGrid-item:last-child': {
			padding: '12px 0px 0px'
		}
	}
}));

type FeedbackProps = {
	children?: React.ReactElement;
	workflowId?: Workflow['id'];
	workflowName?: Workflow['name'];
};

const FeedbackDialog = ({ children, workflowId, workflowName }: FeedbackProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();

	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

	const [, takeScreenShot] = useScreenshot({
		type: 'image/jpeg',
		quality: 1.0
	});

	const getScreenshotFile = async () => {
		const screenshot = await takeScreenShot(document.documentElement);
		const imageData = await fetch(screenshot);
		const blob = await imageData.blob();
		const screenshotFile = new File([blob], 'screenshot.jpg', {
			type: blob.type
		});

		return screenshotFile;
	};

	const [isChecked, setIsChecked] = useState<boolean>(true);
	const profile = useSelector(getProfile);
	const [shareText, setShareText] = useState<string>('');
	const [emails, setEmails] = useState<string[]>([]);
	const [emailText, setEmailText] = useState<string>('');
	const [toggleFeedbackOpen, setToggleFeedbackOpen] = useState<boolean>(false);
	const [emailError, setEmailError] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);

	const resetForm = () => {
		setIsChecked(true);
		setShareText('');
		setEmails([]);
		setEmailText('');
		setEmailError(false);
		setSelectedFiles([]);
	};

	const noDupes = (arr: Array<any>) => {
		return [...new Set(arr)];
	};

	const hasTopLevelDomain = (str: string) => {
		return str.split('.').length > 1 && str.split('.')[1].length > 1;
	};

	const charLimit = 50;

	const handleOpen = async () => {
		setToggleFeedbackOpen(true);
		let workflowFile;

		// Note: need to take a screenshot before showing the feedback dialog
		const screenshotFile = await getScreenshotFile();

		if (workflowId) {
			const response = await Actions.getWorkflow(workflowId, profile?.awsRegion);
			if (response && response.data) {
				workflowFile = new File([response.data], `${sanitizeFilename(workflowName!)}.wfxc`, {
					type: 'application/zip'
				});
			}
		}

		setSelectedFiles(workflowFile ? [workflowFile, screenshotFile] : [screenshotFile]);
	};

	const handleClose = () => {
		setToggleFeedbackOpen(false);
		resetForm();
	};

	const errorCheck = (email: any) => {
		if ((isValidEmail(email.trim()) || email.trim() === '') && hasTopLevelDomain(email)) {
			setEmailError(false);
		} else {
			setEmailError(true);
		}
	};

	const onUpdateInput = (e: any) => {
		setEmailText(e.currentTarget.value);
		errorCheck(e.currentTarget.value);
	};

	const onChipAdd = (chip: any) => {
		setEmails([...emails, emailText]);
		setEmailText('');
	};

	const onChipDelete = (chipToDelete: string, index: any) => {
		if (chipToDelete === profile.email) {
			setIsChecked(false);
		} else {
			setEmails(emails.filter((email, i) => i !== (isChecked ? index - 1 : index)));
		}
	};

	const onSubmit = () => {
		const emailList = isChecked ? [profile.email, ...emails] : emails;
		dispatch(Actions.sendCustomerFeedback(shareText, emailList, selectedFiles));
		setSubmitted(true);
		resetForm();
	};

	return (
		<>
			{children
				? React.cloneElement(children, {
						onClick: (e: any) => {
							// eslint-disable-next-line no-unused-expressions
							// e?.preventDefault?.();
							// console.log(children.props);
							if (children?.props?.onClick) children.props.onClick();
							handleOpen();
						}
				  })
				: undefined}
			{submitted && (
				<AlertDialog
					open={!!setSubmitted}
					setOpen={open => setSubmitted(open)}
					onClose={() => setSubmitted(false)}
					title={t('Customer Feedback')}
					maxWidth="sm"
					content={
						<>
							<Typography className={classes.boldText} gutterBottom>
								{t('feedback:dialog:thank you')}
							</Typography>
							<Typography className={classes.normalText}>
								{t('feedback:dialog:thank you privacy')}
							</Typography>
						</>
					}
					action={() => {}}
					buttonsText={[t('Close')]}
					secondaryButton={false}
				/>
			)}
			<AlertDialog
				open={!!toggleFeedbackOpen}
				setOpen={open => setToggleFeedbackOpen(open)}
				onClose={handleClose}
				title={t('Customer Feedback')}
				maxWidth="sm"
				resource={
					<Grid className={`${classes.items}`} container direction="column">
						<Grid className="pt-0" item>
							<Typography className={`${classes.boldText} text-center`} gutterBottom>
								{t('feedback:dialog:description')}
							</Typography>
							<Typography className={classes.normalText}>
								<Trans
									i18nKey="feedback:dialog:privacy"
									t={t}
									components={[
										<Link
											key="privacy"
											href="https://kmbs.konicaminolta.us/privacy-policy"
											target="_blank"
											rel="noreferrer noopener"
										/>
									]}
								/>
							</Typography>
						</Grid>
						<Grid item>
							<Divider className={classes.divider} variant="fullWidth" />
						</Grid>
						<Grid item container direction="column">
							<Typography className={classes.boldText}>{t('feedback:dialog:who')}</Typography>
							<Typography className={classes.normalText}>
								{t('feedback:dialog:who description')}
							</Typography>
							<FormControlLabel
								style={{ pointerEvents: 'none' }}
								control={
									<Checkbox
										className={classes.checkbox}
										checked={isChecked}
										onChange={e => setIsChecked(!isChecked)}
										style={{ pointerEvents: 'auto' }}
									/>
								}
								label={
									<Typography variant="caption">
										{t('feedback:dialog:who label', { email: profile.email })}
									</Typography>
								}
							/>

							<ChipInput
								className={classes.text}
								allowDuplicates={false}
								style={{ height: 'auto' }}
								variant="outlined"
								value={(isChecked ? [profile.email, ...emails] : emails).map(email =>
									email.length > charLimit ? `${email.substring(0, charLimit)}...` : email
								)}
								onUpdateInput={onUpdateInput}
								onBeforeAdd={() => {
									if (emailError || emailText.trim() === '') {
										return false;
									}
									return true;
								}}
								onAdd={onChipAdd}
								onDelete={onChipDelete}
								FormHelperTextProps={{
									error: emailError
								}}
								helperText={emailError ? t('Invalid email') : undefined}
								InputProps={{
									error: emailError
								}}
								newChipKeys={['Enter', ',', ';', ' ']}
								onPaste={event => {
									event.preventDefault();
									const newEmails = noDupes(
										event.clipboardData
											.getData('Text')
											.split(/[,;\s]+/)
											.filter(email => isValidEmail(email) && hasTopLevelDomain(email))
									);
									setEmails([...emails, ...newEmails]);
								}}
								blurBehavior="add"
							/>
						</Grid>
						<Grid item container direction="column">
							<Grid className="items-center" item container direction="row">
								<Typography className={`${classes.boldText} mr-12`}>
									{t('feedback:dialog:what')}
								</Typography>
								<Typography variant="body1">{`(${t('Required')})`}</Typography>
							</Grid>
							<TextField
								variant="outlined"
								multiline
								rows={3}
								value={shareText}
								onChange={e => {
									setShareText(e.target.value);
								}}
							/>
						</Grid>
						<Grid className="pb-0" item>
							<StyledDropzone
								maxSize={MAX_FILE_SIZE}
								excludeTypes={EXCLUDE_FILE_TYPES}
								files={{ selectedFiles, setSelectedFiles }}
								errorMessages={{
									duplicate: <Trans i18nKey="feedback:dialog:duplicate file error" />,
									size: <Trans i18nKey="feedback:dialog:max file size error" />,
									blocked: <Trans i18nKey="feedback:dialog:blocked file error" />
								}}
								content={
									<>
										<Typography variant="h6" gutterBottom>
											{t('feedback:dialog:files')}
										</Typography>
										<Typography variant="subtitle1" gutterBottom>
											{t('feedback:dialog:files description')}
										</Typography>
										<Typography variant="caption">{t('File(s)')}:</Typography>
										{selectedFiles.length === 0 && (
											<Typography variant="caption">{t('No file(s) selected')}</Typography>
										)}
									</>
								}
							/>
						</Grid>
					</Grid>
				}
				disabledBtn={shareText.length === 0}
				action={onSubmit}
				buttonsText={[t('Send')]}
				secondaryButton={false}
			/>
		</>
	);
};

export default FeedbackDialog;
