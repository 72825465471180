import * as Actions from 'app/store/actions';
import { LoggedOutUserAction, PurgeStateAction, User } from './licenseGroups.types';

export type PeopleState = {
	jobs: {
		[jobID: string]: JobItem;
	};
	selectedJob?: JobItemDetails | null;
	queues: {
		[queueID: string]: JobQueue;
	};
	tenantQueues: {
		[queue: string]: JobQueue;
	};
	queueJobs: {
		[queueID: string]: {
			[jobID: string]: JobItem;
		};
	};
	inbox: {
		[inboxItemID: string]: InboxItem;
	};
	tracker: {
		[jobID: string]: JobItem;
	};
	dropData: {
		username?: string;
		password?: string;
		id?: string;
	};
};

export type GetUserJobsSuccessAction = {
	type: typeof Actions.GET_USER_JOBS_SUCCESS;
	payload: {
		[jobID: string]: JobItem;
	};
};

export type GetSelectedJobDetailsSuccessAction = {
	type: typeof Actions.GET_SELECTED_JOB_SUCCESS;
	payload: JobItemDetails;
};

export type ResetSelectedJobDetailsAction = {
	type: typeof Actions.RESET_SELECTED_JOB_SUCCESS;
};
export type GetUserQueuesAction = {
	type: typeof Actions.GET_USER_QUEUES_SUCCESS;
	payload: {
		[queueID: string]: JobQueue;
	};
};
export type GetTenantQueuesAction = {
	type: typeof Actions.GET_TENANT_QUEUES_SUCCESS;
	payload: {
		[queueID: string]: JobQueue;
	};
};
export type GetQueueJobsAction = {
	type: typeof Actions.GET_QUEUE_JOBS_SUCCESS;
	payload: {
		id: string;
		jobs: {
			[jobID: string]: JobItem;
		};
	};
};

export type GetUserInboxAction = {
	type: typeof Actions.GET_USER_INBOX_SUCCESS;
	payload: {
		[id: string]: InboxItem;
	};
};
export type AcceptJobAction = {
	type: typeof Actions.ACCEPT_JOB_SUCCESS;
};
export type GetTrackerAction = {
	type: typeof Actions.GET_TRACKER_SUCCESS;
	payload: {
		[id: string]: JobItem;
	};
};
export type TrackJobAction = {
	type: typeof Actions.TRACK_JOB_SUCCESS;
	payload: {
		[id: string]: JobItem;
	};
};
export type UntrackJobAction = {
	type: typeof Actions.UNTRACK_JOB_SUCCESS;
	payload: {
		[id: string]: JobItem;
	};
};

export type DropDataAction = {
	type: typeof Actions.GET_DROP_DATA_SUCCESS;
	payload: {
		username: string;
		password: string;
		id: string;
	};
};

export type PeopleActionTypes =
	| GetUserJobsSuccessAction
	| GetSelectedJobDetailsSuccessAction
	| ResetSelectedJobDetailsAction
	| GetUserQueuesAction
	| GetQueueJobsAction
	| GetUserInboxAction
	| AcceptJobAction
	| GetTrackerAction
	| TrackJobAction
	| UntrackJobAction
	| GetTenantQueuesAction
	| DropDataAction
	| LoggedOutUserAction
	| PurgeStateAction;

export enum JobStatus {
	verification = 'verification',
	approval = 'approval',
	action = 'action',
	error = 'error',
	complete = 'complete',
	input = 'input',
	processing = 'processing',
	created = 'created'
}
export type JobHistory =
	| {
			id: string;
			createdAt: number;
			source: {
				type: 'user';
				id: string;
			};
			event:
				| {
						type:
							| 'accept'
							| 'reject'
							| 'fileAttached'
							| 'fileDeleted'
							| 'complete'
							| 'noteAdded'
							| 'done'
							| 'received'
							| 'claim';
				  }
				| {
						type: 'reassign';
						to: {
							type: 'user';
							id: string;
						};
				  };
	  }
	| {
			id: string;
			createdAt: number;
			source: {
				type: 'system';
				id: string;
			};
			event: {
				type: 'fileAttached' | 'created';
			};
	  };
export type JobNote = {
	id: string;
	createdAt: number;
	note: string;
	author: string;
	private: boolean;
};

export type Metadata = {
	ext: string;
	fullname: string;
	name: string;
	size: number;
	date: number;
	uploadedBy: User['id'];
	uuid?: string;
	formsubmissions?: { [formId: string]: JobFormMetadata };
	// dropboxin?: {
	// 	ext: string;
	// 	date: string;
	// 	folder: string;
	// 	fullpath: string;
	// 	name: string;
	// 	count: number;
	// 	id: string;
	// 	fullname: string;
	// 	filesize: number;
	// };
};

export type JobAttachment = {
	headers: Object;
	url: string;
	fileId: string;
	metadata: Metadata;
};

export enum InboxMessageType {
	job = 'job',
	info = 'info',
	error = 'error',
	warning = 'warning'
}

type InboxOriginWorkflow = {
	type: 'workflow';
	id: string;
	name: string;
};
type InboxOriginSystem = {
	type: 'system';
};

export type JobInboxItem = {
	id: string;
	type: InboxMessageType.job;
	status: JobStatus;
	job: string;
	origin: InboxOriginWorkflow;
	createdAt: number;
	// message: string;
	messageKey: string;
	info: {
		[key: string]: string | undefined;
	};
};

export type InfoInboxItem = {
	id: string;
	type: InboxMessageType.info;
	status: undefined;
	origin: InboxOriginWorkflow | InboxOriginSystem;
	createdAt: number;
	// message: string;
	messageKey: string;
	info: {
		[key: string]: string | undefined;
	};
};

export type ErrorInboxItem = {
	id: string;
	type: InboxMessageType.error | InboxMessageType.warning;
	status: JobStatus.error;
	origin: InboxOriginSystem | InboxOriginWorkflow;
	createdAt: number;
	// message: string;
	messageKey: string;
	info: {
		[key: string]: string | undefined;
	};
};

export type InboxItem = JobInboxItem | InfoInboxItem | ErrorInboxItem;

export type JobItem = {
	id: string;
	name: string;
	accepted: boolean;
	status: keyof typeof JobStatus;
	active: boolean;
	owner: string;
	queue: string;
	workflow: {
		id: string;
		name: string;
	};
	nodeNamespace: 'PeopleUser' | 'PeopleGroup' | 'PeopleQueue' | (string & {});
	token: string;
	nodeID: string;
	returnURL: string;
	groupID?: string;
	createdAt: number;
	updatedAt: number;
	drop?: {
		username: string;
		password: string;
		id: string;
	};
};

export type JobTransition = {
	node: any;
	transition: string;
	name: string;
};

export type JobItemDetails = JobItem & {
	history: JobHistory[];
	notes: JobNote[];
	canSeeNextSteps: boolean;
	nextSteps: JobTransition[];
	attachments: JobAttachment[];
	canReject?: boolean;
	nodeConfig: {
		allowReassignment?: boolean;
		groups?: string[];
		nextStep?: boolean;
		sendEmailNotification?: boolean;
		user?: any;
		workflowEngine?: {
			nextStepName: string;
		};
		fileOnly?: boolean;
		metadataOnly?: boolean;
		fileAndMetadata?: boolean;
		readOnly?: boolean;
	};
};

export type JobQueue = {
	id: string;
	name: string;
	groupID: string;
	members: string[];
	active: boolean;
	createdAt: number;
	updatedAt: number;
};

export type JobFormMetadata = {
	formId: string;
	submittedBy: string;
	submittedAt: string;
	fileId?: string;
};

export type JobForm = {
	formId: string;
	submittedBy: string;
	submittedAt: string;
	title: string;
	fileId?: string;
};
