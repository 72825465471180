import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import { RootState } from 'app/store';
import { EntitlementsActionTypes, EntitlementsState } from 'app/store/types';
import _ from 'lodash';
import * as Actions from '../actions';

const initialState: EntitlementsState = {
	permissions: {},
	features: {}
};

const entitlementsReducer = (state = initialState, action: EntitlementsActionTypes) => {
	switch (action.type) {
		case Actions.GET_LICENSE_GROUP_DATA_SUCCESS: {
			const {
				data: { entitlements }
			} = action.payload;
			return {
				...state,
				permissions: entitlements.permissions,
				features: entitlements.features
			};
		}
		case Actions.LOGGED_OUT_USER:
		case Actions.PURGE_STATE:
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'entitlements',
		storage: localForage
	},
	entitlementsReducer
);

// Selectors
export const getEntitlements = ({ entitlements }: RootState) => entitlements;

export const getPermissions = ({ entitlements }: RootState) => entitlements.permissions;

export const getRolePermissionCheck = ({ entitlements }: RootState) => {
	return (permissionString: string) => {
		const permission = _.get(entitlements.permissions, permissionString, false);

		if (!_.isBoolean(permission)) {
			return false;
		}

		return permission;
	};
};

export const getFeaturePermissionCheck = ({ entitlements: { features } }: RootState) => {
	return (key: string) => features[key];
};
