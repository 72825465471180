// import auth from 'app/auth/store/reducers';
import { combineReducers } from 'redux';
import fuse from './fuse';
import site from './site.reducer';
import market from './market.reducer';
import app from './app.reducer';
import profile from './profile.reducer';
import licenseGroups from './licenseGroups.reducer';
import users from './users.reducer';
import userGroups from './userGroups.reducer';
import devices from './devices.reducer';
import deviceGroups from './deviceGroups.reducer';
import inputNodes from './inputNodes.reducer';
import roles from './roles.reducer';
import forms from './forms.reducer';
import formGroups from './formGroups.reducer';
import sampleWorkflows from './sampleWorkflows.reducer';
import workflows from './workflows.reducer';
import workflowGroups from './workflowGroups.reducer';
import logs from './logs.reducer';
import alertSubscriptions from './alertSubscriptions.reducer';
import mpDevices from './mpDevices.reducer';
import mpForms from './mpForms.reducer';
import mpWorkflows from './mpWorkflows.reducer';
import report from './report.reducer';
import people from './people.reducer';
import entitlements from './entitlements.reducer';

const reducer = combineReducers({
	// auth,
	fuse,
	site,
	market,
	app,
	profile,
	licenseGroups,
	users,
	userGroups,
	devices,
	deviceGroups,
	inputNodes,
	roles,
	forms,
	formGroups,
	sampleWorkflows,
	workflows,
	workflowGroups,
	logs,
	alertSubscriptions,
	mpDevices,
	mpForms,
	mpWorkflows,
	report,
	people,
	entitlements
});

export default reducer;

// Selectors
export * from './site.reducer';
export * from './market.reducer';
export * from './app.reducer';
export * from './profile.reducer';
export * from './licenseGroups.reducer';
export * from './users.reducer';
export * from './userGroups.reducer';
export * from './devices.reducer';
export * from './deviceGroups.reducer';
export * from './inputNodes.reducer';
export * from './roles.reducer';
export * from './forms.reducer';
export * from './formGroups.reducer';
export * from './sampleWorkflows.reducer';
export * from './workflows.reducer';
export * from './workflowGroups.reducer';
export * from './logs.reducer';
export * from './alertSubscriptions.reducer';
export * from './mpDevices.reducer';
export * from './mpForms.reducer';
export * from './mpWorkflows.reducer';
export * from './report.reducer';
export * from './people.reducer';
export * from './entitlements.reducer';
