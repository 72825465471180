/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, convertLocalToUTCHack, convertUTCHacktoLocal, getWfxUrl, responseError, responseErrors } from 'app/utils/helpers';
import { Translation } from 'react-i18next';
import {
	getUsers,
	getSelectedLicenseGroupId,
	getProfile,
	getAllUsersById,
	getWorkflows,
	getRoles
} from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { User } from 'app/store/types';
import { useSelector } from 'react-redux';
import * as Actions from 'app/store/actions';
import React from 'react';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as peoplActions from './people.actions';
import { format } from 'date-fns';

const errors = ['unexpected-error'];

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const findSuccessfullyRemovedUserIds = (userIds: User['id'][], res: any) => {
	const userIdsNotDeleted: User['id'][] = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.data && datum.data.user) {
					userIdsNotDeleted.push(datum.data.user);
				}
			});
		}
	});
	return userIds.filter(userId => !userIdsNotDeleted.includes(userId));
};

export const addUsersToUserGroup = (
	payload: {
		users: Array<{ id: string; add?: Array<string>; delete?: Array<string> }>;
	},
	onSuccess = () => {}
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const responses = await axios.patch(`/api/user/${licenseGroupId}/user-group`, payload);

		console.log(responses);
		const err: any = findErrors(responses);

		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (err.length === 0) {
					if (responseErrors(responses).length) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					} else {
						dispatch(appActions.alert(payload.users.length > 1 ? 'users moved' : 'user moved', 'success'));
						onSuccess();
					}
				} else {
					if (err.includes('not-found')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('unauthorized')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('assign-user-group-failed')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('remove-user-group-failed')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('failed to add some users to user group', 'warning'));
					}
					if (err.includes('already-assigned')) {
						dispatch(
							appActions.alert(payload.users.length > 1 ? 'users in group' : 'user in group', 'warning')
						);
					}
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 1 ? 'user:error:move users' : 'user:error:move user'));
	}
};

export const blockMove = (): AppThunk => async (dispatch, getState) => {
	dispatch(appActions.alert('cannot move blocked users', 'warning'));
};

export const resendInvite = (userIds: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await Promise.all(
			userIds.map(userId =>
				axios.patch(`/api/user/${licenseGroupId}/invitation`, {
					send: [userId]
				})
			)
		);
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to resend invite', 'warning'));
		} else {
			dispatch(appActions.alert('invite resent', 'success'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:resend invite'));
	}
};

export const removeLicenseFromUsers = (userIds: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/user/${licenseGroupId}/user/remove-license`, {
			id: userIds[0]
		});
		const err: any = findErrors(responses);
		dispatch(peoplActions.removeUserFromQueues(userIds[0]));
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('user:unassign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('user:unassign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:unassign license:fail'));
	}
};

export const assignLicenseToUsers = (userIds: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);

	try {
		const responses = await axios.patch(`/api/user/${licenseGroupId}/user/assign-license`, {
			id: userIds[0]
		});
		const err: any = findErrors(responses);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (responseErrors(responses).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('user:assign license:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('user:assign license:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:assign license:fail'));
	}
};

export const addUsers = (users: { email: string; roles: string[]; groups: string[] }[]): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		// const response = await Promise.all(
		// 	emails.map(email =>
		// 		axios.patch(`/api/user/${licenseGroupId}`, {
		// 			add: [{ email, roles: [roles[emails.indexOf(email)]] }]
		// 		})
		// 	)
		// );
		// const response = await axios.patch(`/api/user/${licenseGroupId}`, {
		// 	add: emails.map(email => ({ email, roles: [roles[emails.indexOf(email)]] }))
		// });
		const response = await Promise.all(
			_.chunk(users, 10).map(chunk =>
				axios.patch(`/api/user/${licenseGroupId}`, {
					add: chunk.map(user => ({ email: user.email, roles: user.roles, groups: user.groups }))
				})
			)
		);
		console.log(response);
		if (responseErrors(response).length) {
			// dispatch(appActions.alert('failed to add some users', 'warning'));
			if (users.length > 1) {
				dispatch(
					Actions.alert(
						<div>
							<>
								<Translation>{t => t('add users:failed:header')}</Translation>
								<ul className="list-disc ml-24">
									{users.map(user => {
										return <li key={user.email}>{user.email}</li>;
									})}
								</ul>
							</>
						</div>,
						'warning'
					)
				);
			} else {
				dispatch(appActions.alert('failed to add user'));
			}
		} else {
			dispatch(appActions.alert(users.length > 1 ? 'users added' : 'user added', 'success'));
			// emails.forEach(async email => {
			// 	try {
			// 		const apiResponse = await axios.put(`${process.env.REACT_APP_METADATA_URL}/api/v1/user/${email}`, {
			// 			id: email,
			// 			data: {
			// 				last: '',
			// 				domain: 'stratus',
			// 				profile: 'n/a',
			// 				name: 'NA',
			// 				dept: 'unknown',
			// 				title: '',
			// 				email,
			// 				first: '',
			// 				full: '',
			// 				home: '???'
			// 			}
			// 		});
			// 		console.log(apiResponse);
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });
		}

		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:invite user'));
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	}
};

// CHANGEME::once finalized this logic should likely be moved to the backend (it doesn't even get the latest workflows data)
const removeUsersFromWorkflows = (userIds: User['id'][], replaceOwnerWithCurrentUser = true): AppThunk => async (
	dispatch,
	getState
) => {
	const workflows = getWorkflows(getState());
	const newOwnerId = getProfile(getState()).id;
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	const modifiedWorkflowAcls = workflows
		// get list of only relevant workflows
		.filter(workflow => workflow.acl.users.some(({ name }) => userIds.includes(name)))
		// get list of acls with...
		.map(({ id, acl }) => ({
			id,
			acl: {
				...acl,
				users: acl.users
					.map(aclUser =>
						// ...workflow owner users replaced (if applicable), and...
						userIds.includes(aclUser.name) && aclUser.role === 'owner' && replaceOwnerWithCurrentUser
							? { name: newOwnerId, role: 'owner' }
							: aclUser
					)
					// ...removed users deleted
					.filter(({ name }) => !userIds.includes(name))
			}
		}));

	const responses = await Promise.all(
		modifiedWorkflowAcls.map(({ id, acl }) => axios.post(`${wfxApiUrl}/api/wfx/${id}/acl`, acl))
	);

	if (responseErrors(responses).length) {
		throw new Error();
	}
};

export const removeUsers = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const users = getUsers(getState());
	const allUsersById = getAllUsersById(getState());

	let selfDeletion = false;
	for (let i = userIds.length - 1; i >= 0; i -= 1) {
		if (userIds[i] === profile.id) {
			selfDeletion = true;
			userIds.splice(i, 1);
		}
	}

	try {
		const responses = await Promise.all(
			_.chunk(userIds, 10).map(chunk =>
				axios.patch(`/api/user/${licenseGroupId}`, {
					delete: chunk.map(userId => userId)
				})
			)
		);
		const successfullyRemovedUserIds = findSuccessfullyRemovedUserIds(userIds, responses.flat());

		await dispatch(removeUsersFromWorkflows(successfullyRemovedUserIds));
		if (successfullyRemovedUserIds.includes(profile.id)) {
			window.location.reload();
		} else {
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					if (responseErrors(responses).length) {
						dispatch(appActions.alert('failed to remove some users', 'warning'));
					} else {
						const err: any = findErrors(responses);
						if (selfDeletion) {
							err.push('owner');
						}
						if (err.length === 0) {
							dispatch(
								appActions.alert(userIds.length > 1 ? 'users removed' : 'user removed', 'success')
							);
						} else {
							if (err.includes('not-found')) {
								dispatch(appActions.alert('user not found', 'warning'));
							}
							if (err.includes('owner')) {
								const roles = useSelector(getRoles);
								const rolesWithEdit = roles
									.filter(
										role =>
											role.permissions?.settingsTab?.rolesSection?.edit &&
											role.permissions?.settingsTab?.rolesSection?.view
									)
									.map(role => role.id);
								if (
									users.filter(
										user => _.intersection(user.roles, rolesWithEdit).length > 0 && !user.blocked
									).length ===
									userIds.filter(userId =>
										users.find(
											user =>
												user.id === userId &&
												_.intersection(user.roles, rolesWithEdit).length > 0 &&
												!user.blocked
										)
									).length
								) {
									dispatch(appActions.alert('must keep at least one admin', 'warning'));
								} else {
									dispatch(appActions.alert('cannot remove owner', 'warning'));
								}
							}
							if (err.includes('keep-default-user')) {
								dispatch(appActions.alert('keep-default-user', 'warning'));
							}
							if (err.includes('keep-one-admin')) {
								dispatch(appActions.alert('must keep at least one admin', 'warning'));
							}
						}
					}
				})
			);
		}
	} catch (error) {
		dispatch(
			appActions.handleError(
				error,
				userIds.length > 1
					? 'user:error:remove users'
					: allUsersById[userIds[0]].pending
					? 'user:error:cancel invite'
					: 'user:error:remove user'
			)
		);
	}
};

export const changeUserRole = (userIds: User['id'][], roles: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/roles`, {
			users: userIds,
			roles
		});
		const err: any = findErrors(response);
		if (err.length === 0) {
			dispatch(appActions.alert(userIds.length > 1 ? 'user roles updated' : 'user role updated', 'success'));
		} else {
			if (err.includes('unauthorized')) {
				dispatch(appActions.alert('unauthorized', 'warning'));
			}
			if (err.includes('already-assigned-role')) {
				dispatch(appActions.alert('user role already assigned', 'warning'));
			}
			if (err.includes('keep-one-admin')) {
				dispatch(appActions.alert('must keep at least one admin', 'warning'));
			}
			if (err.includes('updated-user-roles-failed')) {
				dispatch(appActions.alert('user:error:change role', 'warning'));
			}
			if (err.includes('unexpected-error')) {
				dispatch(appActions.alert('user:error:change role', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:change role'));
	}
};

export const blockUser = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/block-list`, {
			block: userIds
		});
		const err: any = findErrors(response);
		// if (err.length === 0) {
		// 	dispatch(appActions.alert(userIds.length > 1 ? 'users blocked' : 'user blocked', 'success'));
		// } else {
		// 	if (err.includes('unauthorized')) {
		// 		dispatch(appActions.alert('unauthorized', 'warning'));
		// 	}
		// 	if (err.includes('already-blocked')) {
		// 		dispatch(appActions.alert('user already blocked', 'warning'));
		// 	}
		// 	if (err.includes('pending-user')) {
		// 		dispatch(appActions.alert('cannot block pending user', 'warning'));
		// 	}
		// 	if (err.includes('keep-one-admin')) {
		// 		dispatch(appActions.alert('must keep at least one admin', 'warning'));
		// 	}
		// 	if (err.includes('block-user-failed')) {
		// 		dispatch(appActions.alert('user:error:block user', 'warning'));
		// 	}
		// 	if (err.includes('unexpected-error')) {
		// 		dispatch(appActions.alert('user:error:block user', 'warning'));
		// 	}
		// }
		if (userIds.includes(profile.id)) {
			window.location.reload();
		} else {
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					if (err.length === 0) {
						dispatch(appActions.alert(userIds.length > 1 ? 'users blocked' : 'user blocked', 'success'));
					} else {
						if (err.includes('unauthorized')) {
							dispatch(appActions.alert('unauthorized', 'warning'));
						}
						if (err.includes('already-blocked')) {
							dispatch(appActions.alert('user already blocked', 'warning'));
						}
						if (err.includes('pending-user')) {
							dispatch(appActions.alert('cannot block pending user', 'warning'));
						}
						if (err.includes('keep-one-admin')) {
							dispatch(appActions.alert('must keep at least one admin', 'warning'));
						}
						if (err.includes('block-user-failed')) {
							dispatch(appActions.alert('user:error:block user', 'warning'));
						}
						if (err.includes('unexpected-error')) {
							dispatch(appActions.alert('user:error:block user', 'warning'));
						}
					}
				})
			);
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:block user'));
	}
};

export const unblockUser = (userIds: User['id'][]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/block-list`, { unblock: userIds });
		const err: any = findErrors(response);
		if (err.length === 0) {
			// dispatch(appActions.alert(userIds.length > 1 ? 'users unblocked' : 'user unblocked', 'success'));
			dispatch(
				licenseGroupsActions.getSelectedLicenseGroupData(() => {
					dispatch(appActions.alert(userIds.length > 1 ? 'users unblocked' : 'user unblocked', 'success'));
				})
			);
		} else {
			if (err.includes('unauthorized')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
			if (err.includes('already-unblocked')) {
				dispatch(appActions.alert('user already unblocked', 'warning'));
			}
			if (err.includes('unblock-user-failed')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
			if (err.includes('unexpected-error')) {
				dispatch(appActions.alert('user:error:unblock user', 'warning'));
			}
		}
	} catch (error) {
		dispatch(appActions.handleError(error, 'user:error:unblock user'));
	}
};

export const editUserRoles = (
	id: string,
	roleIds: string[],
	keepGroupRole: boolean,
	groupId: string | undefined,
	option: string,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const data = {
		id,
		roleIds,
		...(!keepGroupRole && groupId && { groupId, option })
	};

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/user-roles`, data);

		const err: any = findErrors(response);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(() => {
				if (err.length === 0) {
					// @ts-ignore
					if (responseError(response)) {
						onError();
						dispatch(appActions.alert('user role:edit:fail', 'warning'));
					} else {
						onSuccess();
						dispatch(appActions.alert('user role:edit:success', 'success'));
					}
				} else {
					if (err.includes('updated-user-roles-failed')) {
						onError();
						dispatch(appActions.alert('user role:edit:fail', 'warning'));
					}
					if (err.includes('unexpected-error')) {
						onError();
						dispatch(appActions.alert('user role:edit:fail', 'warning'));
					}
				}
			})
		);
	} catch (error) {
		// TODO: update error handling
		onError();
		dispatch(appActions.handleError(error));
	}
};

// export const editUsers = (
// 	userIds: User['id'][],
// 	{ permissions: modifiedPermissions }: Partial<User>
// ): AppThunk => async (dispatch, getState) => {
// 	const licenseGroupId = getSelectedLicenseGroupId(getState());
// 	const usersById = getUsersById(getState());

// 	const mergePermissions = (userId: User['id'], newPermissions: User['permissions'] | undefined) => {
// 		return {
// 			...usersById[userId].permissions,
// 			...newPermissions
// 		};
// 	};

// 	try {
// 		// FIXME::commented out to force to work
// 		// const responses = await Promise.all(
// 		// 	_.chunk(userIds, 25).map(chunk =>
// 		// 		axios.patch(
// 		// 			`/api/v1/user/${licenseGroupId}`,
// 		// 			chunk.map(userId => ({ userId, permissions: mergePermissions(userId, modifiedPermissions) }))
// 		// 		)
// 		// 	)
// 		// );
// 		// if (responseErrors(responses).length) {
// 		// 	dispatch(appActions.alert((userIds.length > 1 ? 'failed to update users' : 'failed to update user', 'warning'));
// 		// } else {
// 		// 	dispatch(appActions.alert(userIds.length > 1 ? 'users updated' : 'user updated', 'success'));
// 		// }
// 		dispatch(appActions.alert(userIds.length > 1 ? 'users updated' : 'user updated', 'success'));
// 		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
// 	} catch (error) {
// 		dispatch(appActions.handleError(error));
// 	}
// };

export const schedulePermanentTransfer = (userId: User['id'], toUserId: User['id'], startDate: Date, successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const actionsArr = ['transfer', 'send-email']
	const actions = actionsArr.length === 0 ? 'none' : actionsArr.join(',')
	const response = await axios.post(`/api/transfer-owner/${licenseGroupId}/permanent/${format(convertLocalToUTCHack(startDate), 'yyyyMMddHH')}/${actions}/${userId}/${toUserId}`) 
	successFn();
}

export const scheduleTemporaryTransfer = (userId: User['id'], toUserId: User['id'], startDate: Date, endDate: Date, disableLogin: boolean, message: string, successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const actionsArr = ['redirect', 'send-email']
	if (!disableLogin) {
		actionsArr.push("enable-login")
	}
	const actions = actionsArr.length === 0 ? 'none' : actionsArr.join(',')
	const formattedDate = `${format(convertLocalToUTCHack(startDate), 'yyyyMMddHH')}-${format(convertLocalToUTCHack(endDate), 'yyyyMMddHH')}`
	const response = await axios.post(`/api/transfer-owner/${licenseGroupId}/temporary/${formattedDate}/${actions}/${userId}/${toUserId}`, {
		message: message
	});
	successFn();
}

export const cancelTransfer = (userIds: User['id'][], type: string, successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const pathType = type === 'permanent' ? `permanent` : type === 'temporary' ? 'temporary' : ''
	const responses = await Promise.all(
		userIds.map(userId =>
			axios.post(`/api/transfer-owner/${licenseGroupId}/${pathType}/deactivate/${userId}`)
		)
	);
	successFn();
}

export const removeUser = (userIds: User['id'][], toUserId: User['id'], date: Date | undefined, successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const actionsArr = ['transfer', 'send-email', 'remove-user']
	const actions = actionsArr.length === 0 ? 'none' : actionsArr.join(',')
	const pathType = !!date ? format(convertLocalToUTCHack(date), 'yyyyMMddHH') : 'immediate'
	const responses = await Promise.all(
		userIds.map(userId =>
			axios.post(`/api/transfer-owner/${licenseGroupId}/permanent/${pathType}/${actions}/${userId}/${toUserId}`)
		)
	);
	// const successfullyRemovedUserIds = findSuccessfullyRemovedUserIds(userIds, responses.flat());
	successFn();
}

export const updateOutOfOffice = (userId: User['id'], toUserId: User['id'], startDate: Date, endDate: Date, sendEmail: boolean, customMessage: string, successFn: Function): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const actionsArr = []
	if (userId !== toUserId) {
		actionsArr.push('redirect')
	}
	if (sendEmail) {
		actionsArr.push('send-email')
	}
	const actions = actionsArr.length === 0 ? 'none' : actionsArr.join(',')
	let payload: any = {}
	if (sendEmail) {
		payload.message = _.isEmpty(customMessage) ? undefined : customMessage
	}
	const formattedDate = `${format(convertLocalToUTCHack(startDate), 'yyyyMMddHH')}-${format(convertLocalToUTCHack(endDate), 'yyyyMMddHH')}`
	const response = await axios.post(`/api/transfer-owner/${licenseGroupId}/temporary/${formattedDate}/${actions}/${userId}/${toUserId}`, payload);
	successFn();
}