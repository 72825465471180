/* eslint-disable import/no-cycle */
import axios from 'app/client';
import { getProfile } from 'app/store/reducers';
import { getWfxUrl, responseErrors } from 'app/utils/helpers';

import { AppThunk } from 'app/store';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const importSampleWorkflow = (workflowId: string): AppThunk => async (dispatch, getState) => {
	const profile = getProfile(getState());
	const wfxApiUrl = getWfxUrl(profile?.awsRegion);

	// const params = new URLSearchParams();
	// params.append('group', workflowGroupId ?? '');

	try {
		const response = await axios.request({
			method: 'COPY' as any,
			url: `${wfxApiUrl}/api/samples/${workflowId}`
		});
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to import sample workflow', 'warning'));
		} else {
			dispatch(appActions.alert('sample workflow imported', 'success'));
			dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
		}
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
