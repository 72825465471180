import i18n from 'i18n';
import jwtDecode from 'jwt-decode';
import axios from 'app/client';
import { getPreviousProfileId, getSelectedLicenseGroupId, getProfile as getProfileSelector } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { getMetadataUrl, responseErrors } from 'app/utils/helpers';
import * as appActions from './app.actions';
import { Profile, UserPreferences } from '../types';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const LOGGED_OUT_USER = 'LOGGED_OUT_USER';

//
// HELPERS
//

const getAwsRegionFromJwt = (jwtToken = '') => {
	if (!jwtToken) {
		return 'us-east-1';
	}
	const { iss } = jwtDecode(jwtToken);

	// iss is like https://cognito-idp.us-east-1.amazonaws.com/us-east-1_LA4oPdnmE
	if (iss) {
		const items = iss.split('/');
		const awsRegion = items[2].split('.')[1];
		if (awsRegion) return awsRegion;

		const cognitoAwsRegion = items[3].split('_')[0];
		if (cognitoAwsRegion) return cognitoAwsRegion;
	}
	return 'us-east-1';
};

const massageRawProfile = (rawProfile: any) => {
	const awsRegion = getAwsRegionFromJwt(rawProfile.tokens?.accessToken) || 'us-east-1';

	const profile: Profile = {
		id: rawProfile.id,
		// HACK-ish::fall back to `username` and empty string for firstName and lastName - added for cognito users
		firstName: rawProfile.firstName || rawProfile.username || '',
		lastName: rawProfile.lastName || '',
		email: rawProfile.email,
		mpId: rawProfile.mpId,
		mpData: rawProfile.mpData,
		country: rawProfile.country,
		awsRegion,
		tenantId: rawProfile.tenant,
		tokens: {
			accessToken: rawProfile.tokens?.accessToken,
			idToken: rawProfile.tokens?.idToken,
			expirationDate: +new Date(rawProfile.tokens?.expireTime * 1000)
		},
		preferences: rawProfile.preferences,
		appData: rawProfile.appData ?? {}
	};

	return profile;
};

export const unlinkAccount = (): AppThunk => async (dispatch, getState) => {
	try {
		await axios
			.get(`/api/sso/mp/unlink`)
			.then(() => {
				dispatch(appActions.alert('unlink:success', 'success'));
				dispatch(getProfile());
			})
			.catch(() => {
				dispatch(appActions.alert('unlink:fail', 'warning'));
			});
	} catch (error) {
		dispatch(appActions.alert('unlink:fail', 'warning'));
	}
};

const getUserPreferences = async (tenantId: string) => {
	if (!tenantId) {
		return undefined;
	}
	try {
		const { data: preferences } = await axios.get(`/api/user/${tenantId}/preferences`);
		// HACK-ish::set browser language here
		if (preferences?.language) {
			i18n.changeLanguage(preferences.language);
		}
		return preferences;
	} catch (error) {
		if ([404, 403, 401].includes((error as any).response?.status)) {
			return undefined;
		}
		throw error;
	}
};

const getUserAppData = async (tenantId: string) => {
	if (!tenantId) {
		return undefined;
	}
	try {
		const { data: appData } = await axios.get(`/api/user/${tenantId}/app-data`);
		return appData;
	} catch (error) {
		if ([404, 403, 401].includes((error as any).response?.status)) {
			return undefined;
		}
		throw error;
	}
};

export const editUserAppData = (appData: Partial<Profile['appData']>): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfileSelector(getState());

	const data = {
		...profile.appData,
		...appData
	};

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/app-data`, data);
		// @ts-ignore
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to update user app data', 'warning'));
		} else {
			// dispatch(appActions.alert('user app data saved', 'success'));
		}
		const newAppData = await getUserAppData(licenseGroupId);
		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: {
				data: {
					user: {
						...profile,
						appData: newAppData
					}
				}
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const getProfile = (): AppThunk => async (dispatch, getState) => {
	const previousProfileId = getPreviousProfileId(getState());

	try {
		const { data } = await axios.get('/api/sso/session');
		const { user: rawUser } = data;
		const [preferencesResult, appDataResult] = await Promise.allSettled([
			getUserPreferences(rawUser.tenant),
			getUserAppData(rawUser.tenant)
		]);
		const user = massageRawProfile({
			...rawUser,
			// @ts-ignore // if `value` is not present it means the promise failed which should hit the fallback
			preferences: preferencesResult.value ?? rawUser.preferences,
			// @ts-ignore // if `value` is not present it means the promise failed which should hit the fallback
			appData: appDataResult.value ?? rawUser.appData
		});

		try {
			const emailParts = user.email.split('@');
			const metadataApiUrl = getMetadataUrl(user?.awsRegion);
			const apiResponse = await axios.put(`${metadataApiUrl}/api/v1/user/${user.id}`, {
				id: user.id,
				data: {
					last: user.lastName,
					domain: emailParts[1],
					name: emailParts[0],
					email: user.email,
					first: user.firstName,
					full: `${user.firstName} ${user.lastName}`
				}
			});

			if (apiResponse.status < 200 || apiResponse.status >= 300) {
				console.error('Failed to set user metadata:', apiResponse.status);
			}
		} catch (err) {
			console.log(err);
		}

		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: {
				data: { user }
			}
		});
		if (user && previousProfileId && user.id !== previousProfileId) {
			dispatch({
				type: LOGGED_OUT_USER
			});
			return undefined;
		}
		// attach accessToken to subsequent API calls (needed for `WFX` calls specifically)
		// DEV NOTE::HACK-ish::`tokens` will be undefined on the non-admin side of the app
		// (but we always use it there otherwise so we're assuming it's defined in the type otherwise)
		if (user.tokens?.accessToken) {
			axios.defaults.headers.common.Authorization = `bearer ${user.tokens.accessToken}`;
		}
		return user;
	} catch (error) {
		if ((error as any).response?.status === 401) {
			// if user not logged in
			dispatch({
				type: GET_PROFILE_SUCCESS,
				payload: {
					data: undefined
				}
			});
			dispatch({
				type: LOGGED_OUT_USER
			});
			return undefined;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const saveUserPreferences = (userPreferences: UserPreferences): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfileSelector(getState());

	const data = userPreferences;

	try {
		const response = await axios.patch(`/api/user/${licenseGroupId}/preferences`, data);
		// @ts-ignore
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to save user preferences', 'warning'));
		} else {
			// dispatch(appActions.alert('user preferences saved', 'success'));
		}
		const preferences = await getUserPreferences(licenseGroupId);
		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: {
				data: {
					user: {
						...profile,
						preferences
					}
				}
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const uploadBackgroundImage = (file: File): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const formData = new FormData();
	formData.append('file', file);

	try {
		const response = await axios.post(`/api/user/${licenseGroupId}/preferences/background`, formData, {
			transformRequest: data => data,
			headers: { 'Content-Type': 'multipart/form-data' }
		});
		dispatch(appActions.bumpSiteBackgroundCache());
		// @ts-ignore
		if (responseErrors(response).length) {
			dispatch(appActions.alert('failed to upload background image', 'warning'));
		} else {
			// dispatch(appActions.alert('background image uploaded', 'success'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <UserPreferencesCard />
		throw error;
	}
};
